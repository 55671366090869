import { NgModule } from '@angular/core';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { BrowserModule } from '@angular/platform-browser';
import { initializeApp } from 'firebase/app';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';

initializeApp({
  projectId: 'sarahundtanjaheiraten',
  appId: '1:819545624169:web:2f19964a6b4dee451e9cf8',
  storageBucket: 'sarahundtanjaheiraten.appspot.com',
  apiKey: 'AIzaSyB5f9UMNMVKO4ODjuUnajhr-tCk2wx4LzY',
  authDomain: 'sarahundtanjaheiraten.firebaseapp.com',
  messagingSenderId: '819545624169',
  measurementId: 'G-J1LR55VTHB',
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: FIREBASE_OPTIONS,
      useValue: {
        projectId: 'sarahundtanjaheiraten',
        appId: '1:819545624169:web:2f19964a6b4dee451e9cf8',
        storageBucket: 'sarahundtanjaheiraten.appspot.com',
        apiKey: 'AIzaSyB5f9UMNMVKO4ODjuUnajhr-tCk2wx4LzY',
        authDomain: 'sarahundtanjaheiraten.firebaseapp.com',
        messagingSenderId: '819545624169',
        measurementId: 'G-J1LR55VTHB',
      },
    },
  ],
})
export class AppModule {}
