/* 🤖 this file was generated by svg-to-ts */
export const iconArrow: {
  name: 'arrow';
  data: string;
} = {
  name: 'arrow',
  data: `<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M8 20.201a1.13 1.13 0 0 0 1.597 0L17.8 12 9.597 3.799A1.13 1.13 0 1 0 8 5.396L14.604 12 8 18.604A1.13 1.13 0 0 0 8 20.2Z"/></svg>`
};
export const iconGift: {
  name: 'gift';
  data: string;
} = {
  name: 'gift',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9.375 3a1.875 1.875 0 0 0 0 3.75h1.875v4.5H3.375A1.875 1.875 0 0 1 1.5 9.375v-.75c0-1.036.84-1.875 1.875-1.875h3.193A3.375 3.375 0 0 1 12 2.753a3.375 3.375 0 0 1 5.432 3.997h3.943c1.035 0 1.875.84 1.875 1.875v.75c0 1.036-.84 1.875-1.875 1.875H12.75v-4.5h1.875a1.875 1.875 0 1 0-1.875-1.875V6.75h-1.5V4.875C11.25 3.839 10.41 3 9.375 3Zm1.875 9.75H3v6.75a2.25 2.25 0 0 0 2.25 2.25h6v-9Zm1.5 0v9h6.75a2.25 2.25 0 0 0 2.25-2.25v-6.75h-9Z"/></svg>`
};
export const iconMinus: {
  name: 'minus';
  data: string;
} = {
  name: 'minus',
  data: `<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.25 12a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75Z"/></svg>`
};
export const iconPlus: {
  name: 'plus';
  data: string;
} = {
  name: 'plus',
  data: `<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"/></svg>`
};
export type SthIcon = 'arrow' | 'gift' | 'minus' | 'plus';
export interface SthIconInterface {
  name: SthIcon;
  data: string;
}
export type SthIconInterfaceNameSubset<
  T extends Readonly<SthIconInterface[]>
> = T[number]['name'];
export const completeIconSet = [iconArrow, iconGift, iconMinus, iconPlus];
