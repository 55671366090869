import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Router } from '@angular/router';
import {
  GoogleAuthProvider,
  UserCredential,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { Observable, of } from 'rxjs';
import { SthUser } from '../types/user.type';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private router: Router,
    private db: AngularFirestore,
    private functions: AngularFireFunctions
  ) {}

  public getAuthUserUID(): string | undefined {
    const auth = getAuth();

    return auth.currentUser?.uid;
  }

  public async loginViaGoogle(): Promise<UserCredential> {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    auth.languageCode = 'de';
    return signInWithPopup(auth, provider);
  }

  public loginViaEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential> {
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, password);
  }

  public authenticateViaEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential> {
    const auth = getAuth();
    return createUserWithEmailAndPassword(auth, email, password);
  }

  public logout(): void {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        this.router.navigate(['/']);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  public getResetPasswordLink$(email: string): Observable<string> {
    return this.functions.httpsCallable('resetPassword')({
      email,
    });
  }

  public fetchAllUsers$(): Observable<SthUser[] | undefined> {
    const userId = this.getAuthUserUID();
    if (!userId) {
      return of(undefined);
    }
    return this.db
      .collection<SthUser>('users', (ref) =>
        ref.where('roles', 'array-contains', 'user')
      )
      .valueChanges();
  }

  public fetchAllAdmins$(): Observable<SthUser[] | undefined> {
    const userId = this.getAuthUserUID();
    if (!userId) {
      return of(undefined);
    }
    return this.db
      .collection<SthUser>('users', (ref) =>
        ref.where('roles', 'array-contains', 'admin')
      )
      .valueChanges();
  }
}
