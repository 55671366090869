import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable, of } from 'rxjs';
import { SthMusic } from '../types/music.type';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class MusicService {
  constructor(
    private db: AngularFirestore,
    private functions: AngularFireFunctions,
    private userService: UserService
  ) {}

  public submitMusic(createMusicDTO: {
    link?: string;
    title?: string;
    interpret?: string;
  }): Observable<any> {
    return this.functions.httpsCallable('createMusic')({
      ...createMusicDTO,
      uid: this.userService.getAuthUserUID() ?? '',
    });
  }

  public fetchMusic$(): Observable<SthMusic[] | undefined> {
    const userId = this.userService.getAuthUserUID();
    if (!userId) {
      return of(undefined);
    }
    return this.db
      .collection<SthMusic>('music', (ref) =>
        ref.where('creatorId', '==', userId)
      )
      .valueChanges();
  }

  public fetchAllMusic$(): Observable<SthMusic[] | undefined> {
    const userId = this.userService.getAuthUserUID();
    if (!userId) {
      return of(undefined);
    }
    return this.db.collection<SthMusic>('music').valueChanges();
  }
}
